import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Card,
  Button,
  Divider,
  Typography,
  Result,
  Dropdown,
  Segmented,
  Spin,
  Modal,
  Avatar,
  Image,
  Table,
  Space,
  message,
  Form,
  QRCode,
} from "antd";
import {
  ProForm,
  ProFormText,
  DrawerForm,
  ProFormSelect
} from '@ant-design/pro-components';
import { Link, useParams } from 'react-router-dom'
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import Lottie from 'react-lottie';
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import * as animationData from '../../../../../assets/lottie/done.json'
import * as animationScand from '../../../../../assets/lottie/scand.json'
import './calendar.css';
const { Title, Text, Paragraph } = Typography;
const numberFormatter = Intl.NumberFormat("en-US");

const Page = () => {
    const [form] = Form.useForm();
    const [ calendarData, setCalendarData ] = useState([])
    const [ step, setStep ] = useState(0)
    const [ loading, setLoading ] = useState(false)
    const [ selectDate, setSelectDate] = useState('')
    const [ menuTab, setMenuTab] = useState('Calendar')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { authLiff, isLogin, sleep } = useContext(MainContext);

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    
    const today = new Date();
    const todayDate = today.getDate();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();

    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const list_emo = [
      [{label:'calm',color:'#cccccc'},{label:'happy',color:'#cccccc'},{label:'loved',color:'#cccccc'},{label:'proud',color:'#cccccc'}],
      [{label:'surprised',color:'#cccccc'},{label:'anxiety',color:'#cccccc'},{label:'sad',color:'#cccccc'},{label:'angry',color:'#cccccc'}],
      [{label:'disappointed',color:'#cccccc'},{label:'scared',color:'#cccccc'}]
    ]
    
    useEffect(() => {  
      if(isLogin==true){
        fetchHistory()
      }
    }, [isLogin]);  

    useEffect(() => {  
      if(step==0){
        setStep(1)
        setTimeout  (async() => {  
          setStep(0)
        } , 100)
      }
    }, [calendarData]);

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleSelectEmo = async(emo) => {  
      console.log(emo,selectDate)
      setLoading(true)
      try {
        const accessToken = await authLiff.getAccessToken()
        fetch(`/api/liff/kuber/event/well_being/calendar`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                  label: selectDate,
                  emo: emo
                })
            })
            .then((response) => response.json())
            .then(async(data) => {
                setCalendarData((prevHistory) => prevHistory.concat([{
                  label: selectDate,
                  emo: emo
                }]));
                setLoading(false)
                setIsModalOpen(false);
            }).catch((error) => {
              setLoading(false)
              message.info('Please try again.');
            });
      } catch(error) {
        setLoading(false)
        message.info('Please try again..');
      }
    };


    const handleSelectDate = (event) => {
      const raw = event.target.getAttribute('label');
      const [year, month, date] = raw.split('-').map(item=>parseFloat(item));
      const isStart = month >= 9 && year >= 2024;
      const isToday = date === todayDate && month === todayMonth && year === todayYear;
      const isFuture = (year > todayYear) || (year === todayYear && month > todayMonth) || (year === todayYear && month === todayMonth && date > todayDate);
      if((!isFuture || isToday)){
        console.log(event,raw);
        setSelectDate(raw);
        showModal();
      } else {  
        message.info('You can only select the past date');
      }
    }

    const fetchHistory = async() => {
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/well_being/calendar/history`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  setCalendarData(data.data)
              }).catch((error) => {
                setCalendarData([])
              });
      } catch(error) {
        setCalendarData([])
        message.info('Please try again...');
      }
    }

    const generateRandomInteger = (min, max) => {
      return Math.floor(min + Math.random()*(max - min + 1))
    }

    const renderCalendar = (month, year) => {
      const firstDay = new Date(year, month).getDay();
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      var index_emo = {}
      console.log('calendarData==>',calendarData)
      calendarData.map((item)=>{
        index_emo[item.label] = item.emo
      })
      console.log('index_emo==>',index_emo)
      let date = 1;
      const rows = [];
      for (let i = 0; i < 6; i++) {
        const row = [];
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < firstDay) {
            row.push(<td key={j}></td>);
          } else if (date > daysInMonth) {
            break;
          } else {
            const isToday = date === todayDate && month === todayMonth && year === todayYear;
            const isFuture = (year > todayYear) || (year === todayYear && month > todayMonth) || (year === todayYear && month === todayMonth && date > todayDate);

            row.push(
              <td key={j} className={isToday? (index_emo[year+'-'+month+'-'+date]? 'emo '+index_emo[year+'-'+month+'-'+date] : 'today') : (isFuture? 'future' :  (index_emo[year+'-'+month+'-'+date]? 'emo '+index_emo[year+'-'+month+'-'+date] : 'date') )} onClick={(input)=>handleSelectDate(input)}>
                <span label={year+'-'+month+'-'+date}>{isToday? '.' : isFuture? date : (date)}</span>
              </td>
            );
            date++;
          }
        }
        rows.push(<tr key={i}>{row}</tr>);
      }
      return rows;
    };
  
    const prevMonth = () => {
      if((currentMonth>=10 && currentYear==2024) || currentYear>2024){
        setCurrentMonth((prev) => (prev === 0 ? 11 : prev - 1));
        if (currentMonth === 0) setCurrentYear((prev) => prev - 1);
      }
    };
  
    const nextMonth = () => {
      setCurrentMonth((prev) => (prev === 11 ? 0 : prev + 1));
      if (currentMonth === 11) setCurrentYear((prev) => prev + 1);
    };


    const RenderCalendar = () => {
      return (
        <div className="calendar">
          <div className="title_logo">
              <img src="/img/liff/event/well_being/title_calendar.svg"/>    
          </div>
          
          <div className="header">
            {((currentMonth>=10 && currentYear==2024) || currentYear>2024)? (
              <Button type="text" icon={<LeftOutlined style={{color:"#557ABD"}} />} onClick={prevMonth}/>
            ) : (<div></div>)}
            <div id="month">{`${months[currentMonth]} ${currentYear}`}</div>
            <Button type="text" icon={<RightOutlined style={{color:"#557ABD"}} />} onClick={nextMonth}/>
          </div>
          <table>
            <thead>
              <tr>
                <th>Sun</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
              </tr>
            </thead>
            <tbody id="calendar-body">
              {step==0 && renderCalendar(currentMonth, currentYear)}
            </tbody>
          </table>
          <div className="calendar_footer">
              <img src="/img/liff/event/well_being/footer.svg"/>    
          </div>
        </div>)
    }

    const RenderFeelings = () => {  
      return (<></>) 
    }

  // <LinkAccount liffId={'1657440707-Lm4VW3VJ'}>
  return (
      <LinkAccount liffId={'1657440707-Lm4VW3VJ'}>
	  	<div style={{minHeight:'100vh',width:'100%',backgroundPosition: 'center',padding:20}} className="well_being">
            <Row gutter={[0, 0]} className="mb-10">
              {/* <Col span={24}>
              <Segmented
                defaultValue="center"
                style={{ marginBottom: 8 }}
                onChange={async(value) => {
                  // onfetch()
                  setMenuTab(value)
                }}
                options={['Calendar', 'Feelings']}
              />
              </Col> */}
              <Col span={24}>
                  {menuTab=="Calendar" && (<RenderCalendar  />)}
                  {menuTab=="Feelings" && (<RenderFeelings />)}
              </Col>
            </Row>
            <Modal title="&nbsp;" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className="modal_felling" footer={null}>
              <Spin spinning={loading} tip="Loading...">
              <table className="select_emo">
                <tbody>
                {list_emo.map((item,index)=>{
                  return (<tr key={'tr-'+index}>
                    {item.map((emo,node)=>{
                      return (<td key={'td-'+index+'-'+node} onClick={()=>handleSelectEmo(emo.label)}><div className={`emo ${emo.label}`}></div><p style={{color:emo.color,textAlign:'center',fontWeight:'700',fontSize:emo.label=='disappointed'? 8 : 10}}>{emo.label}</p></td>)
                    })}
                  </tr>)
                })}
                </tbody>
              </table>
              </Spin>
            </Modal>
	    </div>
      </LinkAccount>
  );
};


export default Page;